import customRules from "./validator.rules";
import moment from "moment";

export default {
  checkAvailability(object, method) {
    return (this.hasProp(object.validate, 'required')) || (object.value && object.value.length) ? method : false;
  },
  hasProp: (object, key) => Object.prototype.hasOwnProperty.call({ ...object }, key),
  /***
   Basic Validation Func
   ***/
  validateField: function(f) {
    if (typeof f !== "object" || !this.hasProp(f, 'validate')) {
      return true;
    }

    // Required fields checks if value is not empty
    if (f.validate.required) {
      // console.log( f )
      if (f.value.length == 0) {
        return {
          error: f.validate.required.error
        };
      }
    }

    if (this.checkAvailability(f, f.validate.beforeTomorrow)) {
      const today = new Date()
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);

      try {
        const [day, month, year] = f.value.split('.');
        const current = new Date(year, parseInt(month) - 1, day, 0, 0, 0);

        if (current.getTime() > today.getTime()) {
          return {
            error: f.validate.beforeTomorrow.error
          };
        }
      } catch (e) {
        return false;
      }
    }

    // Pattern
    if (this.checkAvailability(f, f.validate.pattern)) {
      const reg = new RegExp(f.validate.pattern.reg);
      // console.log(reg)
      if (!f.value.match(reg)) {
        return {
          error: f.validate.pattern.error
        };
      }
    }

    // Min
    if (f.validate.min) {
      if (f.value.length < f.validate.min.treshold) {
        return {
          error: f.validate.min.error
        };
      }
    }

    // Max
    if (f.validate.max) {
      if (f.value.length > f.validate.max.treshold) {
        return {
          error: f.validate.max.error
        };
      }
    }

    // Is
    if (typeof f.validate.is !== "undefined") {
      // in
      if (f.validate.is.in) {
        if (f.validate.is.in.indexOf(f.value) < 0) {
          return {
            error: f.validate.is.in.error || f.validate.is.error
          };
        }
      }
      // equal
      if (typeof f.validate.is.equal !== "undefined") {
        if (f.validate.is.equal != f.value) {
          return {
            error: f.validate.is.equal.error || f.validate.is.error
          };
        }
      }
      // not
      if (typeof f.validate.is.not !== "undefined") {
        if (f.validate.is.not == f.value) {
          return {
            error: f.validate.is.not.error || f.validate.is.error
          };
        }
      }
      // greaterThan
      if (typeof f.validate.is.greaterThan !== "undefined") {
        // console.log( f.value, f.validate.is.greaterThan, f.validate.is.greaterThan >= f.value )
        if (isNaN(parseFloat(f.value)) || (parseFloat(f.validate.is.greaterThan.treshold) > parseFloat(f.value))) {
          return {
            error: f.validate.is.greaterThan.error || f.validate.is.error
          };
        }
      }
      // lesserThan
      if (typeof f.validate.is.lesserThan !== "undefined") {
        // console.log( f.value, f.validate.is.lesserThan )
        if (isNaN(parseFloat(f.value)) || (parseFloat(f.validate.is.lesserThan.treshold) < parseFloat(f.value))) {
          return {
            error: f.validate.is.lesserThan.error || f.validate.is.error
          };
        }
      }
      // greaterThanDate
      if (typeof f.validate.is.greaterThanDate !== 'undefined') {
        // console.log('greaterThanDate', f)
        let formattedRef = moment(f.refDateValue, 'DD.MM.YYYY')
        let formattedValue = moment(f.value, 'DD.MM.YYYY')
        // console.log(`Введенная дата (${formattedValue}) больше доступной (${formattedRef})?`, !formattedValue.isSameOrBefore(formattedRef))
        if (!formattedValue.isSame(formattedRef)) {
          if (!formattedValue.isAfter(formattedRef)) {
            return {
              error: f.validate.is.error
            }
          }
        }
      }
      // lesserThanDate
      if (typeof f.validate.is.lesserThanDate !== 'undefined') {
        // console.log('lesserThanDate', f)
        let formattedRef = moment(f.refDateValue, 'DD.MM.YYYY')
        let formattedValue = moment(f.value, 'DD.MM.YYYY')
        // console.log(`Введенная дата (${formattedValue}) меньше доступной (${formattedRef})?`, !formattedValue.isSameOrAfter(formattedRef))
        if (!formattedValue.isSame(formattedRef)) {
          if (!formattedValue.isBefore(formattedRef)) {
            return {
              error: f.validate.is.error
            }
          }
        }
      }
    }

    // Custom callback for validation
    if (f.validate.callback) {
      return f.validate.callback(f.value);
    }

    if (f.validate.custom) {
      if (Array.isArray(f.validate.custom)) {
        const rules = f.validate.custom.map(rule => customRules[rule]);
        const results = rules.reduce((invalid, rule) => {
          if (rule) {
            const validator = rule.call(null, f.value);

            return validator.valid ? invalid : [...invalid, validator];
          }

          return invalid;
        }, []);

        if (results.length) {
          const validator = results.shift();

          return {
            error: validator.message
          };
        }
      } else if (customRules[f.validate.custom]) {
        const rule = customRules[f.validate.custom];
        const { valid, message } = rule.call(null, f.value);

        if (!valid) {
          return {
            error: message
          };
        }
      }
    }

    return true;
  }
  /***
   Form validation func
   ***/
  // validateForm : function( e ) {
  // 	var f = e.data
  // 	//console.log(f)
  // 	e.preventDefault()
  // 	e.stopPropagation()
  // 	var errors = []
  // 	//console.log(f)
  // 	for( var k in f.fields ) {
  //
  // 		if(  f.fields.hasOwnProperty( k )  ) {
  // 			var $field = $( '[name=' + k + ']' )
  // 		//	console.log(k, $(this) )
  // 			var v = $field.val()
  // 			f.fields[k].value = v
  // 		//	console.log(f)
  // 			var result = this.validateField(f.fields[k])
  // 			//console.log(result)
  // 			if( result !== true ) {
  // 				if( typeof f.fields[k].onError == 'function' ) {
  // 					f.fields[k].onSubmitError( $field, result )
  // 				}
  // 				else {
  // 					this.onSubmitError( $field, result )
  // 				}
  // 				errors.push( {field: $field, error: result} )
  // 			}
  // 			else {
  // 				if( typeof f.fields[k].onFieldSuccess == 'function' ) {
  // 					f.fields[k].onFieldSuccess( $field )
  // 				}
  // 				else {
  // 					this.onFieldSuccess( $field )
  // 				}
  // 			}
  // 		}
  //     // endfor
  // 	}
  //
  //   //
  //   if( errors.length == 0 ) {
  //     var $form = $( e.currentTarget )
  //     var form = this.forms.filter( function( form ) {
  //       //console.log( form.id, $form )
  //       return form.id == $form.attr('id')
  //     } )
  //     if( form.length == 0 ) {
  //       console.log( 'No form set' )
  //       this.onFormSuccess( $(e.currentTarget) )
  //     }
  //     else {
  //       //
  //       if( typeof form[0].onFormSuccess == 'function' ) {
  //         form[0].onFormSuccess( $(e.currentTarget) )
  //       }
  //       else {
  //         this.onFormSuccess( $(e.currentTarget) )
  //       }
  //       //
  //     }
  //   }
  // 	else {
  // 		//console.log()
  // 		if( typeof this.onFormError == 'function' ) {
  // 			this.onFormError(errors)
  // 		}
  // 	}
  // },
  //
  // validateSingleField: function( e ) {
  // 	//console.log(1)
  // 	var field = e.data
  // 	console.log(field)
  // 	var $field = $( e.currentTarget )
  // 	field.value = $field.val()
  // 	var result = this.validateField( field )
  // 	//console.log(result)
  // 	if( result !== true ) {
  // 		if( typeof field.onError == 'function' ) {
  // 			field.onFieldError($field, result )
  // 		}
  // 		else {
  // 			this.onFieldError($field, result )
  // 		}
  // 	}
  // 	else {
  // 		if( typeof field.onFieldSuccess == 'function' ) {
  // 			field.onFieldSuccess( $field )
  // 		}
  // 		else {
  // 			this.onFieldSuccess( $field )
  // 		}
  // 	}
  // },
  //
  // validateUs: function( forms, c=false ) {
  // 	this.forms = forms
  //   //Handlers
  //   for( var i=0; i<forms.length; i++ ) {
  //   	$('#'+forms[i].id).on('submit', forms[i], this.validateForm.bind(this) );
  //   	for( var k in forms[i].fields ) {
  //   		//console.log($( '#'+forms[i].id ).find( '[data-field="' + k + '"]' ))
  //   		$( '[name=' + k + ']' ).on('keyup', forms[i].fields[k], this.validateSingleField.bind(this) )
  //   		$( '[name=' + k + ']').on('change', forms[i].fields[k], this.validateSingleField.bind(this) )
  // 			try {
  // 		    //Set placeholder
  // 		    if( typeof forms[i].fields[k].placeholder != 'undefined' && forms[i].fields[k].placeholder.length > 0  ) {
  // 		      $( '[name=' + k + ']').attr( 'placeholder', forms[i].fields[k].placeholder )
  // 		    }
  // 				if( !c || c.clearValues === false ) {
  // 			    //Set value
  // 			    if( typeof forms[i].fields[k].value != 'undefined' && forms[i].fields[k].value.length > 0  ) {
  // 			      $( '[name=' + k + ']' ).val( forms[i].fields[k].value  )
  // 			    }
  // 				}
  // 		  }
  // 			catch(e) {
  // 				console.log( forms[i].fields[k], k, e )
  // 			}
  //   	}
  //   }
  // }
};
